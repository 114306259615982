import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import axios from 'axios'
import imgDoctor from '../../assets/images/pharmacist.png';
import imgPatient from '../../assets/images/patient.png';
import newImgPatient from '../../assets/images/new_patient.png';
import { useTranslation } from "react-i18next";
import { setLocaleHcw, setLocalePatient, setAlternate, setCurrentNodeId, setErrorMessage, setAppTree } from "../../js/actions/index";
import { ACCESS_TOKEN_NAME, API_LOCALES_APPTREE, _DOMAIN } from '../../js/constants/apiConstants';
import { LOADING_STATUS_START, LOADING_STATUS_STARTED, LOADING_STATUS_ENDED, LOADING_STATUS_ERROR } from '../../js/constants/apiConstants';
import { Card, Row, Col, Form, Image, Button, Spinner } from 'react-bootstrap';

const mapStateToProps = state => {
  return {
    localeHcw: state.localeHcw,
    localePatient: state.localePatient,
    alternate: state.alternate,
    appTree: state.appTree
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setLocaleHcw, setLocalePatient, setAlternate, setCurrentNodeId, setErrorMessage, setAppTree }, dispatch)
}

// * For language selection
function LocaleSelector(props) {
  const { t } = useTranslation('common');
  const [localesSource, setLocalesSource] = useState([])
  const [localesDestination, setLocalesDestination] = useState([])
  const notAssignedLocale = 'not-assigned'
  const [loadingStatus, setLoadingStatus] = useState(1)

  // useEffect(() => {
  //   console.log("props.appTree", props.appTree)
  //   if (!props.appTree) {
  //     const appTreeId = localStorage.getItem("appTreeId")
  //     console.log("appTreeId", appTreeId)
  //     props.setAppTree(appTreeId)
  //   }
  // }, [props.appTree])

  // TODO localesDestination should have global state
  useEffect(() => {
    if (localesSource.length > 0 && !props.localeHcw) {
      props.setLocaleHcw(localesSource[0].iso_code)
      localStorage.setItem("localeHcw", localesSource[0].iso_code)
    }
    if (localesDestination.length > 0 && !props.localePatient) {
      props.setLocalePatient(localesDestination[0].iso_code)
      localStorage.setItem("localePatient", localesDestination[0].iso_code)
    }
    if (loadingStatus === LOADING_STATUS_START) {
      setLoadingStatus(LOADING_STATUS_STARTED)
      const url = _DOMAIN + API_LOCALES_APPTREE + '?appTreeId=' + localStorage.getItem("appTreeId") //props.appTree
      const urlSource = url + '&type=source'
      const urlDestination = url + '&type=destination'
      const config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }

      // * GETs available locales for pharmacist and patient
      axios.all([
        axios.get(urlSource, config),
        axios.get(urlDestination, config)
      ])
        .then(axios.spread((response1, response2) => {
          // Both requests are now complete
          if (response1.status === 200 && response2.status === 200) {
            setLocalesSource(response1.data.data.items)
            setLocalesDestination(response2.data.data.items)
            setLoadingStatus(LOADING_STATUS_ENDED)
          } else {
            setLoadingStatus(LOADING_STATUS_ERROR)
            props.setErrorMessage(t('api.generic_error'))
          }

        }));
    }
  })

  function setLangs(e) {
    if (props.localeHcw && props.localePatient) {
      // clean previous nodes selected (for example in a search)
      localStorage.setItem("localeHcw", props.localeHcw)
      localStorage.setItem("localePatient", props.localePatient)
      props.setCurrentNodeId(null)
      props.history.push('/main')
    }
  }

  function handleChangeLangHcw(e) {
    props.setLocaleHcw(e.target.value)
    localStorage.setItem("localeHcw", e.target.value)
  }

  function handleChangeLangPatient(e) {
    props.setLocalePatient(e.target.value)
    localStorage.setItem("localePatient", e.target.value)
  }

  function handleChangeAlternante(e) {
    props.setAlternate(e.target.checked)
  }

  return (
    <>
      <div style={{ marginTop: "8px" }}>
        <div className="language-selector-header">
          <p className="button-text semi-bold" style={{ padding: "10px 0", textTransform: "uppercase" }}>{t('app.select_languages')}</p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div style={{ width: "40%" }}>
            {/* // * Language selection form */}
            <Form.Group controlId="langForm.langHcw">
              <Form.Label className="subtitle-text semi-bold" style={{ marginBottom: "0" }}>{t('app.hcw')}</Form.Label>
              <hr style={{ margin: "0 0 .2rem", borderColor: "black" }} />
              {loadingStatus === LOADING_STATUS_ENDED ?
                <Form.Control as="select"
                  className="subtitle-text"
                  style={{ backgroundColor: "transparent", border: "0", textTransform: "uppercase", padding: "0" }}
                  onChange={handleChangeLangHcw}
                  defaultValue={props.localeHcw}>
                  <option disabled value={notAssignedLocale}>{t('app.select_language')}</option>
                  {localesSource.map((locale) => (
                    <option key={locale.iso_code} value={locale.iso_code}>{locale.name}</option>
                  ))}
                </Form.Control>
                :
                <div className="text-center mb-4">
                  <Spinner animation="border" size='sm' />
                </div>}
            </Form.Group>
          </div>
          <div style={{ width: "40%" }}>
            <Form.Group controlId="langForm.langPatient">
              <Form.Label className="subtitle-text semi-bold" style={{ marginBottom: "0" }}>{t('app.patient')}</Form.Label>
              <hr style={{ margin: "0 0 .2rem", borderColor: "black" }} />
              {loadingStatus === LOADING_STATUS_ENDED ?
                <Form.Control as="select"
                  className="subtitle-text"
                  style={{ backgroundColor: "transparent", border: "0", textTransform: "uppercase", padding: "0" }}
                  onChange={handleChangeLangPatient}
                  defaultValue={props.localePatient}>
                  <option disabled value={notAssignedLocale}>{t('app.select_language')}</option>
                  {localesDestination.map((locale) => (
                    <option key={locale.iso_code} value={locale.iso_code}>{locale.name}</option>
                  ))}
                </Form.Control>
                :
                <div className="text-center mb-4">
                  <Spinner animation="border" size='sm' />
                </div>}
            </Form.Group>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={setLangs}
            className="btn my-buttons semi-bold"
            style={{ width: "100%" }}
            block
            disabled={loadingStatus !== LOADING_STATUS_ENDED}
          >{t('app.ok')}</button>
        </div>
        <div className="landing-img-container">
          <div>
            <Image src={imgDoctor} className="landing-img" />
          </div>
          <div style={{ textAlign: "end" }}>
            <Image src={newImgPatient} className="landing-img" />
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocaleSelector));
