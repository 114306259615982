import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { setCurrentSpeaker, setAlternate, setCurrentNodeId, setForceChangeSpeaker } from "../js/actions/index";
import { Row, Col, Image } from 'react-bootstrap';
import { CURRENT_SPEAKER_HCW, CURRENT_SPEAKER_PATIENT } from '../js/constants/appConstants';

import axios from 'axios';
import { API_NODE, ACCESS_TOKEN_NAME, _DOMAIN } from '../js/constants/apiConstants';

const mapStateToProps = state => {
  return {
    currentSpeaker: state.currentSpeaker,
    alternate: state.alternate,
    localeHcw: state.localeHcw,
    localePatient: state.localePatient,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentSpeaker, setAlternate, setCurrentNodeId, setForceChangeSpeaker }, dispatch)
}

function Footer(props) {
  const [footerDisplayData, setFooterDisplayData] = useState([])
  const location = useLocation();

  const pharmacistStrings = footerDisplayData.length ? footerDisplayData[0].sentence : ''
  const patientStrings = footerDisplayData.length ? footerDisplayData[1].sentence : ''

  // TODO: Make dictionaries a global state and use it to populate all the strings of the app instead of the hardcoded values used now
  // * Fetches dictionary data and sets state for the footer tabs names 
  useEffect(() => {
    if (location.pathname === '/main') {
      const DICTIONARY_NODE_ID = 19464
      const localeHcw = localStorage.getItem("localeHcw")
      const localePatient = localStorage.getItem("localePatient")
      // TODO: One URL variable to rule them all
      const url = `${_DOMAIN}${API_NODE}?node-id=${DICTIONARY_NODE_ID}&locale-code-1=${localeHcw}&locale-code-2=${localePatient}&levels=2`
      // TODO: One config to rule them all
      const config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }

      axios.get(url, config).then(response => {
        setFooterDisplayData(response.data.data.descendants);
      })
    }
  }, [location.pathname, props.localeHcw, props.localePatient])

  if (location.pathname !== '/main') return ('');

  function handleCurrentSpeakerHcw() {
    if (props.currentSpeaker !== CURRENT_SPEAKER_HCW) {
      props.setForceChangeSpeaker(true)
    }
  }

  function handleCurrentSpeakerPatient() {
    if (props.currentSpeaker !== CURRENT_SPEAKER_PATIENT) {
      props.setForceChangeSpeaker(true)
    }
  }


  return (
    <div className='speakers' >
      <div className={"button-speaker " + ((props.currentSpeaker === CURRENT_SPEAKER_HCW) ? 'current-speaker' : '')} onClick={handleCurrentSpeakerHcw}>
        <div className='speaker-text' >
          <span style={{ fontWeight: "900" }} >{props.currentSpeaker === 'hcw' ? pharmacistStrings?.locale1?.value : pharmacistStrings?.locale2?.value}</span>
        </div>
      </div>
      <div className={"button-speaker " + ((props.currentSpeaker === CURRENT_SPEAKER_PATIENT) ? 'current-speaker' : '')} onClick={handleCurrentSpeakerPatient}>
        <div className='speaker-text' >
          <span style={{ fontWeight: "600" }} >{props.currentSpeaker === 'hcw' ? patientStrings?.locale1?.value : patientStrings?.locale2?.value}</span>
        </div>
      </div>
    </div>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));