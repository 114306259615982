import React, { useEffect, useState, useRef } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { API_NODE, API_AUDIO, ACCESS_TOKEN_NAME, API_TREE, _DOMAIN } from '../../js/constants/apiConstants';
import { LOADING_STATUS_INIT, LOADING_STATUS_START, LOADING_STATUS_STARTED, LOADING_STATUS_ENDED, LOADING_STATUS_ERROR } from '../../js/constants/apiConstants';
import { CURRENT_SPEAKER_HCW, CURRENT_SPEAKER_PATIENT } from '../../js/constants/appConstants';
import axios from 'axios'
import { Card, Row, Col, Spinner } from 'react-bootstrap';
import ReactPlayer from 'react-player'
import { bindActionCreators } from 'redux';
import {
	setCurrentNodeId,
	setActor1NodeId,
	setActor2NodeId,
	setCurrentSpeaker,
	setForceChangeSpeaker,
	setGoBack,
	setGoToMainMenu,
	setErrorMessage,
	setDocument,
	setDrawer,
	setNodesHistory,
	setSearchString,
	setBackFromSearch,
} from "../../js/actions/index";
import { useTranslation } from "react-i18next";
import NewNodeTree from '../NodeTree/NewNodeTree';
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import Speakers from '../Speakers';
import DisplaySearchBar from '../Search/DisplaySearchBar';

const mapStateToProps = state => {
	return {
		currentSpeaker: state.currentSpeaker,
		localeHcw: state.localeHcw,
		localePatient: state.localePatient,
		alternate: state.alternate,
		currentNodeId: state.currentNodeId,
		actor1NodeId: state.actor1NodeId,
		actor2NodeId: state.actor2NodeId,
		forceChangeSpeaker: state.forceChangeSpeaker,
		goBack: state.goBack,
		goToMainMenu: state.goToMainMenu,
		appTree: state.appTree,
		document: state.document,
		drawer: state.drawer,
		nodesHistory: state.nodesHistory,
		backFromSearch: state.backFromSearch,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setCurrentNodeId,
		setActor1NodeId,
		setActor2NodeId,
		setCurrentSpeaker,
		setForceChangeSpeaker,
		setGoBack,
		setGoToMainMenu,
		setErrorMessage,
		setDocument,
		setDrawer,
		setNodesHistory,
		setSearchString,
		setBackFromSearch,
	}, dispatch)
}

function Main(props) {
	const { t, i18n } = useTranslation('common');
	const [loadingStatus, setLoadingStatus] = useState(LOADING_STATUS_INIT)
	// * Contains consejos escritos PDFs links
	// const [consejosEscritosData, setConsejosEscritosData] = useState([])
	const [quickMenuData, setQuickMenuData] = useState([])
	// * Controls the sentences to be displayed
	const [sentences, setSentences] = useState([])
	// * defaultSentence contains all the main menu categories data
	const [defaultSentence, setDefaultSentence] = useState([])
	// * Displays the last selected node as title
	const [nodeTitle, setNodeTitle] = useState('')
	// * Last played sentence URL
	const [nodePlayURL, setnodePlayURL] = useState(null)
	// * The step is used to fire different functions. Seems like state 1 is used when main menu loads for the first time and then only state 2 is used
	const [step, setStep] = useState(0)
	// * nextNode exists when the speaker changes
	const [nextNode, setNextNode] = useState(null)
	// * Temporary fix. Used to compare global locale patient to trigger change in sentences when language is changed on the navbar dropdown
	const [trackLocalePatient, setTrackLocalePatient] = useState('')
	// * Keep record of current node to add it to history when a search is done
	const [currentNode, setCurrentNode] = useState([])

	const pdfWrapperRef = React.useRef();

	const localeHcw = localStorage.getItem("localeHcw")
	const localePatient = localStorage.getItem("localePatient")

	useEffect(() => {
		// * If app is coming back from search page, set the proper node in history
		if (props.backFromSearch.length) {
			changeNode(props.backFromSearch[0], false, props.backFromSearch[1], null)
		}
	}, [props])

	useEffect(() => {
		setTrackLocalePatient(props.localePatient)
	}, [props.localePatient])

	useEffect(() => {
		// * GET and set side panel data
		const SIDE_PANEL_NODE_ID = '19193'

		// TODO: One URL variable to rule them all
		const url = `${_DOMAIN}${API_NODE}?node-id=${SIDE_PANEL_NODE_ID}&locale-code-1=${localeHcw}&locale-code-2=${localePatient}&levels=2`
		// TODO: One config to rule them all
		const config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }

		axios.get(url, config).then(response => setQuickMenuData(response.data.data.descendants))
	}, [props.localeHcw, props.localePatient])

	// console.log(props.localePatient + ' for patient; ' + props.localeHcw + ' for hcw')
	//i18n.changeLanguage('ru_RU');
	// TODO: Make this hook more manageable
	useEffect(() => {
		if (props.forceChangeSpeaker) {
			// console.log('entro a force Change Speaker')
			props.setForceChangeSpeaker(false)
			switchFooterSpeaker()
			if (props.alternate && !nextNode) {
				// when they force manually to change the speaker despite is automatic
				props.setCurrentNodeId(null);
			} else {
				if (nextNode) {
					// pull node if the node is not a leaf (nextNode is null)
					// console.log('PULL NODE IN USEEFFECT')
					pullNode(true, nextNode)
					setNextNode(null)
				}
			}
		}
		if (props.document && step === 2 && loadingStatus === LOADING_STATUS_ENDED) {
			// console.log('entro a step == 2 && loadingStatus === LOADING_STATUS_START')
			setLoadingStatus(LOADING_STATUS_STARTED)
			// ? GETs consejos escritos, but seems to be unreachable code for now. Compare the slight differences that it has with (step === 2 && loadingStatus === LOADING_STATUS_START)
			const url = _DOMAIN + API_NODE
				+ '?node-id=' + 18982
				+ '&locale-code-1=' + localeHcw //props.localeHcw
				+ '&locale-code-2=' + localePatient //props.localePatient
				+ '&levels=2'
			const config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }
			axios.get(url, config)
				.then(function (response) {
					if (response.status === 200) {
						swapConsejosEscritos(response.data.data.descendants)
						setSentences(response.data.data.descendants)
						let title = response.data.data.sentence.locale1.value ?? ''
						if (props.currentSpeaker !== CURRENT_SPEAKER_HCW) {
							title = response.data.data.sentence.locale2.value ?? ''
						}
						setNodeTitle(title)
						setLoadingStatus(LOADING_STATUS_ENDED)
						props.setDocument(false)
					} else {
						setLoadingStatus(LOADING_STATUS_ERROR)
					}
				})
				.catch(function (error) {
					setLoadingStatus(LOADING_STATUS_ERROR)
					props.setErrorMessage(t('api.generic_error'))
				});
		}

		if (props.goBack) {
			// console.log('entro a goBack')
			props.setGoBack(false)
			goBack()
		}
		if (props.goToMainMenu) {
			// console.log('entro a goToMainMenu')
			props.setGoToMainMenu(false)
			goToMainMenu()
		}
		if (!props.currentNodeId && props.actor1NodeId && loadingStatus === LOADING_STATUS_ENDED) {
			// console.log('entro a !props.currentNodeId && props.actor1NodeId')
			if (props.currentSpeaker === CURRENT_SPEAKER_HCW) {
				props.setCurrentNodeId(props.actor1NodeId)
			} else {
				props.setCurrentNodeId(props.actor2NodeId)
			}
			setNodeTitle('')
			// setStackNodes([])
			// * WHEN SPEAKER IS MANUALLY CHANGED HISTORY IS EMPTIED
			props.setNodesHistory([])
			setStep(2)
			setLoadingStatus(LOADING_STATUS_START)
		}
		if (step === 1 && loadingStatus === LOADING_STATUS_ENDED && props.currentNodeId) {
			// specific case when it arrives from search
			// it could be needed to refactor after step 1 has been moved to localeselector
			// console.log('entro a step == 1 && loadingStatus === LOADING_STATUS_ENDED && props.currentNodeId')
			setNodeTitle('')
			// setStackNodes([])
			// props.setNodesHistory([])
			setStep(2)
			setLoadingStatus(LOADING_STATUS_START)
		}
		if (step === 1 && loadingStatus === LOADING_STATUS_START) {
			// console.log('entro a step == 1 && loadingStatus === LOADING_STATUS_START')
			setLoadingStatus(LOADING_STATUS_STARTED)
			// * GET initial nodes of the tree for pharmacist and patient
			const url = _DOMAIN + API_TREE + '?apptree=' + localStorage.getItem("appTreeId") //props.appTree
			const config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }
			axios.get(url, config)
				.then(function (response) {
					setLoadingStatus(LOADING_STATUS_ENDED)
					if (response.status === 200) {
						props.setActor1NodeId(parseInt(response.data.data.actor1_initial_node))
						props.setActor2NodeId(parseInt(response.data.data.actor2_initial_node))
					} else {
						setLoadingStatus(LOADING_STATUS_ERROR)
					}
				})
				.catch(function (error) {
					setLoadingStatus(LOADING_STATUS_ERROR)
					props.setErrorMessage(t('api.generic_error'))
					// console.log('error' + error)
				});
		}
		// ! OR part added to make it work when language is changed in the Header. Remove it after implementing Redux
		if ((step === 2 && loadingStatus === LOADING_STATUS_START) || (trackLocalePatient && props.localePatient !== trackLocalePatient)) {
			// console.log('entro a step == 2 && loadingStatus === LOADING_STATUS_START')
			setLoadingStatus(LOADING_STATUS_STARTED)
			// * GETs node sentences and its descendants
			const url = _DOMAIN + API_NODE
				+ '?node-id=' + props.currentNodeId
				+ '&locale-code-1=' + localeHcw //props.localeHcw
				+ '&locale-code-2=' + localePatient //props.localePatient
				+ '&levels=2'
			const config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }
			axios.get(url, config)
				.then(function (response) {
					if (response.status === 200) {
						swapConsejosEscritos(response.data.data.descendants)
						setSentences(response.data.data.descendants)
						if (defaultSentence.length == 0) { setDefaultSentence(response.data.data.descendants) }
						let title = response.data.data.sentence.locale1.value ?? ''
						if (props.currentSpeaker !== CURRENT_SPEAKER_HCW) {
							title = response.data.data.sentence.locale2.value ?? ''
						}
						setNodeTitle(title)
						setLoadingStatus(LOADING_STATUS_ENDED)
					} else {
						setLoadingStatus(LOADING_STATUS_ERROR)
					}
				})
				.catch(function (error) {
					setLoadingStatus(LOADING_STATUS_ERROR)
					props.setErrorMessage(t('api.generic_error'))
				});
		}
	})

	if (!props.localeHcw || !props.localePatient) {
		props.history.push('/home')
		return null
	} else {
		if (step === 0 && loadingStatus === LOADING_STATUS_INIT) {
			setStep(step + 1)
			setLoadingStatus(LOADING_STATUS_START)
		}
	}

	function changeNode(newId, isLeaf, isAlternateSpeakerNode, sentenceIdToPlay = null) {
		// Play a sentence automatically
		if (sentenceIdToPlay != null) playSentence(sentenceIdToPlay)

		if (isLeaf) return;

		// when is needed to change the speaker but autoalternate is not set
		//   we store the nextId to use it when they click to change manually
		if (isAlternateSpeakerNode) {
			setNextNode(newId)
			if (props.alternate) {
				props.setForceChangeSpeaker(true)
			}
		} else {
			// deep into the tree without changing speaker
			// console.log('PULL NODE IN CHANGENODE')
			pullNode(isAlternateSpeakerNode, newId)
		}
	}

	function pullNode(isAlternateSpeakerNode, newId) {
		setCurrentNode([newId, isAlternateSpeakerNode])
		props.setCurrentNodeId(newId)
		setNodeTitle('')
		setLoadingStatus(LOADING_STATUS_START)
		if (!props.backFromSearch.length) {
			props.setNodesHistory([...props.nodesHistory, [props.currentNodeId, isAlternateSpeakerNode]])
		}
		props.setBackFromSearch([])
	}

	function goBack() {
		if (props.nodesHistory.length > 0) {
			const lastNode = [...props.nodesHistory].pop()
			const removedLastNodeFromHistory = props.nodesHistory.slice(0, -1)
			// * if last node was a search, go back to search page, set the search string and remove it from history
			if (lastNode[0]?.isSearchString) {
				props.setSearchString(lastNode[0].searchString)
				props.setNodesHistory(removedLastNodeFromHistory)
				props.history.push('/search')
			}
			else {
				props.setNodesHistory(removedLastNodeFromHistory)
				// * Each nested array contains the node id on index 0 and a boolean which changes the current speaker if true in index 1
				if (lastNode[1]) {
					// not possible to use setForceChangeSpeaker because it push nodes into the stack
					// ! This was causing a bug when main menu was being pushed to history and then the back arrow were used it will show the menus in the patient's language
					// ! Left uncommented for now in case it breaks something else
					switchFooterSpeaker()
				}
				setNodeTitle('')
				setLoadingStatus(LOADING_STATUS_START)
				props.setCurrentNodeId(lastNode[0])
			}
		}
	}

	function goToMainMenu() {
		if (props.currentSpeaker === CURRENT_SPEAKER_PATIENT) {
			pullNode(true, props.actor1NodeId)
			props.setCurrentSpeaker(CURRENT_SPEAKER_HCW)
		} else {
			pullNode(false, props.actor1NodeId)
		}
	}

	function switchFooterSpeaker() {
		let newSpeaker = CURRENT_SPEAKER_HCW
		if (props.currentSpeaker === CURRENT_SPEAKER_HCW) {
			newSpeaker = CURRENT_SPEAKER_PATIENT
		}
		props.setCurrentSpeaker(newSpeaker);
	}
	function playSentence(localeSentenceId) {
		const theURL = _DOMAIN + API_AUDIO + '?localeSentenceId=' + localeSentenceId
		setnodePlayURL(theURL)
	}

	const swapConsejosEscritos = (sentences) => {
		const includesConsejosEscritos = sentences.findIndex(sentence => sentence.id === 18982)
		if (includesConsejosEscritos > 0) {
			// * Changes the order of the sentences to have consejos escritos as the first entry while keeping the motivo de la visita and juego de sintomas in second and third position
			[sentences[0], sentences[1], sentences[2], sentences[includesConsejosEscritos]] = [sentences[includesConsejosEscritos], sentences[0], sentences[1], sentences[2]]
		}
	}

	return (
		<>
			<DisplaySearchBar currentNode={currentNode} />
			<Speakers />
			<Row className="node-row" ref={pdfWrapperRef}>
				<div className="mt-2 card-container">
					<Card className='card-standard transparent'>
						<Card.Body>
							{/* // * This is the title displayed after selecting a section from main menu */}
							{nodeTitle &&
								<Card.Title className="mb-4 text-center">
									<strong>{nodeTitle}</strong>
								</Card.Title>}
							<>
								{step === 2 && loadingStatus === LOADING_STATUS_ENDED ?
									sentences.map((sentence) => (
										<SidebarMenu.Nav style={{ "backgroundColor": "#FFFF" }}>
											<SidebarMenu.Nav.Link>
												<SidebarMenu.Nav.Title>
													<NewNodeTree
														key={sentence.id}
														id={sentence.id}
														pdfWrapperRef={pdfWrapperRef}
														sentence1={sentence.sentence.locale1}
														sentence2={sentence.sentence.locale2}
														changeNode={changeNode}
														playSentence={playSentence}
														isLeaf={sentence.isLeaf}
														showTranslation={sentence.showTranslation}
														currentSpeaker={props.currentSpeaker}
														localePatient={props.localePatient}
														localeHcw={props.localeHcw}
														downloadConsejosEscritos={sentence.sentence.locale1.documentId ? sentence.sentence.locale1.documentId : null}
														// * If sentence is consejos escritos node return it's special design
														styling={sentence.id === 18982 ? "consejos-escritos semi-bold" : "node-tree semi-bold"} />
												</SidebarMenu.Nav.Title>
											</SidebarMenu.Nav.Link>
										</SidebarMenu.Nav>
									))
									:
									<div className="text-center mb-4">
										<Spinner animation="border" size='sm' />
									</div>}
							</>
						</Card.Body>
					</Card>

					<Card className='card-standard hide'>
						<Card.Body>
							<Card.Text>
								<Row>
									<Col>
										<strong>automatic alternate: </strong>
										<label>{props.alternate ? 'Yes' : 'NO'}</label>
									</Col>
									<Col>
										<strong>locale1: </strong>
										<label>{props.localeHcw}</label>
									</Col>
									<Col>
										<strong>locale2: </strong>
										<label>{props.localePatient}</label>
									</Col>
								</Row>
							</Card.Text>
						</Card.Body>
					</Card>

					<ReactPlayer
						className="react-player"
						playing
						controls={false}
						url={nodePlayURL}
						config={{
							file: {
								forceAudio: true
							}
						}}
					/>
				</div>
				<div className="mt-2" style={{ display: !props.drawer ? "none" : "block" }}>
					{props.drawer &&
						<Card className='card-standard transparent' style={{ width: "100%" }}>
							<Card.Body>
								{step === 2 && loadingStatus === LOADING_STATUS_ENDED ?
									quickMenuData.map((sentence) => (
										<NewNodeTree
											key={sentence.id}
											id={sentence.id}
											pdfWrapperRef={pdfWrapperRef}
											sentence1={sentence.sentence.locale1}
											sentence2={sentence.sentence.locale2}
											changeNode={changeNode}
											playSentence={playSentence}
											isLeaf={sentence.isLeaf}
											showTranslation={sentence.showTranslation}
											currentSpeaker={props.currentSpeaker}
											localePatient={props.localePatient}
											localeHcw={props.localeHcw}
											styling={"quickmenu semi-bold"} />
									)) :
									<div className="text-center mb-4">
										<Spinner animation="border" size='sm' />
									</div>}
							</Card.Body>
						</Card>}
				</div>
			</Row>
		</>
	)

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
