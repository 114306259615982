import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { setCurrentNodeId, setNodesHistory, setSearchString } from "../../js/actions/index";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { API_AUDIO, _DOMAIN, API_FILES } from '../../js/constants/apiConstants';
import ReactPlayer from 'react-player';
import PDFOptionsModal from '../PDFOptionsModal';
import { IoCloudDownloadOutline, IoVolumeHighSharp } from 'react-icons/io5';
// import PDFViewer from '../PDFViewer';

const mapStateToProps = state => {
	return {
		nodesHistory: state.nodesHistory,
		searchString: state.searchString
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setCurrentNodeId, setNodesHistory, setSearchString }, dispatch)
}

function SearchElement(props) {
	const [nodePlayURL, setnodePlayURL] = useState(null)
	const [viewPDF, setViewPDF] = useState(false)
	const [filePDF, setFilePDF] = useState()
	const [filePatientPDF, setFilePatientPDF] = useState()

	function setNode(newId, isSentence) {

		if (!isSentence) {
			props.setCurrentNodeId(newId)
			props.setNodesHistory([...props.nodesHistory, [{ isSearchString: true, searchString: props.searchString }]])
			props.setSearchString(null)
			// props.setNodesHistory([...props.nodesHistory, [newId, false]])
			props.history.push('/main')
		}
	}

	function playSentence(localeSentenceId) {
		const theURL = _DOMAIN + API_AUDIO + '?localeSentenceId=' + localeSentenceId
		setnodePlayURL(theURL)
	}

	function handleClick() {
		setNode(props.nodeId, props.isSentence)

		if (props.sentence.sentence2Audio) {
			playSentence(props.sentence.sentenceId2)
		}

		else if (props.sentence.sentence1PDF) {
			const filePharmacistUrl = `${_DOMAIN}${API_FILES}?localeSentenceId=${props.sentence.sentenceId1}`
			const filePatientUrl = `${_DOMAIN}${API_FILES}?localeSentenceId=${props.sentence.sentenceId2}`

			setFilePDF(filePharmacistUrl)
			setFilePatientPDF(filePatientUrl)
			setViewPDF(!viewPDF)
		}
	}

	return (
		<div className='py-2 my-1 node-tree'>
			<Col onClick={handleClick}>
				<Row>
					<Col>
						<div className="text-center">
							<span
								className='sentence'
							>{props.sentence.sentence1}</span>
						</div>
					</Col>
				</Row>
				{props.isSentence &&
					<Row>
						<Col>
							<div className="text-center font-italic">
								<span
									className='sentence'
								>{props.sentence.sentence2}</span>
							</div>
						</Col>
						{props.sentence.sentence2Audio ? <IoVolumeHighSharp className='list-icon' /> : <IoCloudDownloadOutline className='list-icon' />}
					</Row>
				}
			</Col>

			<ReactPlayer
				className="react-player"
				playing
				controls={false}
				url={nodePlayURL}
				config={{
					file: {
						forceAudio: true
					}
				}}
			/>

			{viewPDF && <PDFOptionsModal filePDF={filePDF} filePatientPDF={filePatientPDF} viewPDF={viewPDF} setViewPDF={setViewPDF} />}
		</div>
	)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchElement));
