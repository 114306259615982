import React from 'react';
import legalNotice from '../../assets/files/Legal_Notice_ESP.pdf';
import privacyPolicy from '../../assets/files/Privacy_Policy.pdf';
import cookiesPolicy from '../../assets/files/Menarini_Cookies_Policy_Esp-V2.pdf';

export default function Footer() {
  const printPDF = (file) => {
    window.open(file);
  }

  return (
    <div className="footer">
      <div onClick={() => printPDF(legalNotice)} className='footer-section'>
        <span>Nota legal</span>
      </div>
      <div onClick={() => printPDF(privacyPolicy)} className='footer-section'>
        <span>Política de privacidad</span>
      </div>
      <div onClick={() => printPDF(cookiesPolicy)} className='footer-section'>
        <span>Cookies</span>
      </div>
    </div>
  )
}
