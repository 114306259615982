import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { ACCESS_TOKEN_NAME } from '../../js/constants/apiConstants';
import { NavDropdown, Nav, Navbar, Container, Image } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import logo from '../../assets/images/logo_app_header.png';
import homeImg from '../../assets/images/home.png'
import logoutImg from '../../assets/images/logout.png'
import printImg from '../../assets/images/print_red.png'
import backArrowImg from '../../assets/images/back_arrow.png'
import { IoFlash, IoLanguageOutline } from 'react-icons/io5';
import { setSearchString, setGoBack, setGoToMainMenu, setDocument, setDrawer, setCurrentNodeId, setLocaleHcw, setLocalePatient, setAlternate, setErrorMessage } from "../../js/actions/index";

// ! Copied from localeselector
import axios from 'axios';
import { LOADING_STATUS_START, LOADING_STATUS_STARTED, LOADING_STATUS_ENDED, LOADING_STATUS_ERROR } from '../../js/constants/apiConstants';
import { API_LOCALES_APPTREE, _DOMAIN } from '../../js/constants/apiConstants';

const mapStateToProps = state => {
  return {
    appTree: state.appTree,
    currentNodeId: state.currentNodeId,
    localePatient: state.localePatient,
    nodesHistory: state.nodesHistory,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setSearchString, setGoBack, setGoToMainMenu, setDocument, setDrawer, setCurrentNodeId, setLocaleHcw, setLocalePatient, setAlternate, setErrorMessage }, dispatch)
}

function Header(props) {
  const { t, i18n } = useTranslation('common');
  // * handles opening the quick menu
  const [open, setOpen] = useState(false)

  // ! Code from LocaleSelector starts
  // TODO: Repeated state and code in LocaleSelector. Make this global state (probably should update redux first, before it gets too big)
  // * for language selection menu
  const [localesSource, setLocalesSource] = useState([])
  const [localesDestination, setLocalesDestination] = useState([])
  const [loadingStatus, setLoadingStatus] = useState(1)

  const isLoggedIn = window.localStorage.getItem(ACCESS_TOKEN_NAME)

  useEffect(() => {
    if (props.location.pathname === '/main') {
      if (localesSource.length > 0 && !props.localeHcw) {
        props.setLocaleHcw(localesSource[0].iso_code)
        localStorage.setItem("localeHcw", localesSource[0].iso_code)
      }
      if (localesDestination.length > 0 && !props.localePatient) {
        props.setLocalePatient(localesDestination[0].iso_code)
        localStorage.setItem("localePatient", localesDestination[0].iso_code)
      }
      if (loadingStatus === LOADING_STATUS_START) {
        setLoadingStatus(LOADING_STATUS_STARTED)
        var url = _DOMAIN + API_LOCALES_APPTREE + '?appTreeId=' + localStorage.getItem("appTreeId") //props.appTree
        var urlSource = url + '&type=source'
        var urlDestination = url + '&type=destination'
        var config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }

        // * GETs available locales for pharmacist and patient
        axios.all([
          axios.get(urlSource, config),
          axios.get(urlDestination, config)
        ])
          .then(axios.spread((response1, response2) => {
            // Both requests are now complete
            if (response1.status === 200 && response2.status === 200) {
              setLocalesSource(response1.data.data.items)
              setLocalesDestination(response2.data.data.items)
              setLoadingStatus(LOADING_STATUS_ENDED)
            } else {
              setLoadingStatus(LOADING_STATUS_ERROR)
              props.setErrorMessage(t('api.generic_error'))
            }

          }));
      }
    }
  })

  function handleChangeLangPatient(eventKey) {
    props.setLocalePatient(eventKey)
    localStorage.setItem("localePatient", eventKey)
  }

  // ! Ends localeSelector duplicated code

  function renderLogout() {
    if (isLoggedIn && props.location.pathname === '/home') {
      return (
        <div className="logout-button-with-text" onClick={handleLogout}>
          <Image src={logoutImg} className="logout-icon" style={{ marginRight: "12px" }} />
          {t('app.logout')}
        </div>
      )
    }
    if (isLoggedIn && ['/main', '/search'].includes(props.location.pathname)) {
      return (
        <div className="logout-icon-container" onClick={handleLogout}>
          <Image src={logoutImg} className="top-icon-img" style={{ margin: "4px 2px" }} />
        </div>
      )
    }
    else {
      return (
        <div></div>
      )
    }
  }
  function renderDocument() {
    if (['/main', '/search'].includes(props.location.pathname)) {
      return (
        <Image src={printImg} className="top-icon-img" style={{ margin: "2px 8px" }} onClick={handleDocument} />
      )
    }
  }

  function renderQuick() {
    if (['/main'].includes(props.location.pathname)) {
      return (
        <IoFlash onClick={() => handleQuick()} className="top-icon right" />
      )
    }
  }

  function renderTitle() {
    // TODO: Move Dropdown code to its own component
    // TODO: Fix Dropdown opens inside navbar on mobile
    return (
      <>
        {props.location.pathname === '/search' ?
          <div className="header-icons">
            <Image src={backArrowImg} className="top-icon-img" onClick={goBack} style={{ marginLeft: '137px' }} />
          </div>
          :
          <div className="header-icons">
            <NavDropdown
              id="header-sidebar-area"
              title={<IoLanguageOutline className='top-icon' />}
              onSelect={handleChangeLangPatient}
            >
              {localesDestination.map((locale) =>
                <NavDropdown.Item key={locale.id} eventKey={locale.iso_code} >{locale.name}</NavDropdown.Item>
              )}
            </NavDropdown>
            {props.nodesHistory.length > 0 &&
              <>
                <Image src={homeImg} className='top-icon-img' style={{ margin: "2px 12px" }} onClick={goToMainMenu} />
                <Image src={backArrowImg} className="top-icon-img" onClick={goBack} style={{ margin: "2px 12px" }} />
              </>
            }
          </div>
        }
      </>
    )
  }

  function goToMainMenu() {
    props.setGoToMainMenu(true)
  }

  function handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN_NAME)
    localStorage.removeItem("appTreeId")
    localStorage.removeItem("localeHcw")
    localStorage.removeItem("localePatient")

    props.history.push('/')
  }

  function handleDocument() {
    props.setDocument(true)
    props.history.push('/main')
  }

  function handleQuick() {
    setOpen(!open)
    props.setDrawer(!open)
  }

  function goBack() {
    props.setGoBack(true)
  }

  return (
    <Navbar collapseOnSelect expand="lg" variant="light" className="navbar">
      {props.location.pathname === '/login' &&
        <Nav className="order-sm-1 order-0 header-login">
          <img src={logo} alt="Logo" id="logo-login" />
        </Nav>
      }
      {(isLoggedIn && ['/main', '/search'].includes(props.location.pathname)) &&
        <Container style={{ width: "100%", justifyContent: "space-between" }}>
          <Nav className="order-sm-0 order-1">
            {renderTitle()}
          </Nav>
          <Nav
            className="order-sm-1 order-0 header-title"
            onClick={() => props.history.push('/home')}
            style={{ cursor: "pointer" }}
          >
            <img src={logo} alt="Logo" id="logoUD" />
          </Nav>
          <Nav className="my-2 my-lg-0 order-sm-2 order-2">
            <div className="header-icons">
              {renderDocument()}
              {renderQuick()}
              {/* {renderLogout()} */}
            </div>
          </Nav>
        </Container>
      }
      {isLoggedIn && props.location.pathname === '/home' &&
        <Container>
          <Nav>
            <img src={logo} alt="Logo" id="logo-home" />
          </Nav>
          <Nav>
            {/* {renderLogout()} */}
          </Nav>
        </Container>
      }
    </Navbar>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
