import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import MandatoryCookies from "./MandatoryCookies";
import PerformanceCookies from "./PerformanceCookies";

export default function MoreOptionsModal({ show, accept, reject, goBack }) {
  const [performanceCookiesAccepted, setPerformanceCookiesAccepted] = useState(false);

  function handleMoreInfoLink(e) {
    if (!window.confirm("Está a punto de abandonar el sitio web de Universal Pharmacist España, el enlace que va a visitar no aplica nuestra política de privacidad y puede contener publicidad sobre medicamentos e indicaciones no aprobadas en España.")) {
      e.preventDefault();
    }
  }

  return (
    <Modal
      show={show}
      dialogClassName='cookie-consent-modal'
      size='md'
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <div className="cookie-consent-modal-content">
        <div className="cookie-consent-modal-header">
          <h6 style={{ fontWeight: 600 }}>Centro de preferencia de la privacidad</h6>
          <p style={{ fontSize: "10px" }}>
            Cuando visita cualquier sitio web, el mismo podría obtener o guardar información en su navegador, generalmente mediante el uso de cookies.
            Esta información puede ser acerca de usted, sus preferencias o su dispositivo, y se usa principalmente para que el sitio funcione según lo esperado.
            Por lo general, la información no lo identifica directamente, pero puede proporcionarle una experiencia web más personalizada.
            Ya que respetamos su derecho a la privacidad, usted puede escoger no permitirnos usar ciertas cookies.
            Haga clic en los encabezados de cada categoría para saber más y cambiar nuestras configuraciones predeterminadas.
            Sin embargo, el bloqueo de algunos tipos de cookies puede afectar su experiencia en el sitio y los servicios que podemos ofrecer.

            <span style={{ display: "block" }}>
              <a
                href={"https://cookiepedia.co.uk/giving-consent-to-cookies"}
                target="_blank"
                className="cookie-consent-links"
                style={{ color: "red" }}
                onClick={handleMoreInfoLink}
              >
                Más información
              </a>
            </span>
          </p>
        </div>
        <div className="cookie-consent-modal-options">
          <Button
            className="cookie-consent-buttons"
            onClick={accept}
            style={{ maxWidth: "45%" }}
          >
            Permitirlas todas
          </Button>
          <h6 style={{ fontWeight: 600, margin: "32px 0 16px" }}>Gestionar las preferencias de consentimiento</h6>
          {/* // TODO: Switch cookies */}
          <MandatoryCookies />
          <PerformanceCookies performanceCookiesAccepted={performanceCookiesAccepted} setPerformanceCookiesAccepted={setPerformanceCookiesAccepted} />
        </div>
        <div className="cookie-consent-modal-footer" style={{ textAlign: "right" }}>
          <Button
            className="cookie-consent-buttons"
            onClick={goBack}
            style={{ maxWidth: "45%", marginTop: "16px" }}
          >
            Volver
          </Button>
          <Button
            className="cookie-consent-buttons"
            onClick={performanceCookiesAccepted ? accept : reject}
            style={{ maxWidth: "45%", marginTop: "16px", marginLeft: "8px" }}
          >
            Confirmar mis preferencias
          </Button>
        </div>
      </div>
    </Modal>
  );
}