import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { Modal } from 'react-bootstrap';
import logo from '../../assets/images/menarini_logo.png';

const QRGenerator = ({ show, url, close }) => {
  return (
    <Modal
      show={show}
      dialogClassName='qr-modal'
      size='md'
      onHide={close}
      backdrop='static'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='pdf-modal-header' closeButton>
        <div className="pdf-modal-header-container">
          <Modal.Title className='pdf-modal-title' >
            Consejos en tu dispositivo
          </Modal.Title>
        </div>
      </Modal.Header>
      <QRCodeCanvas
        value={url}
        includeMargin
        size={256}
        level={'H'}
        imageSettings={{
          src: logo,
          x: undefined,
          y: undefined,
          height: 24,
          width: 24,
          excavate: false,
        }}
      />
    </Modal>
  )
}

export default QRGenerator