import React from 'react';
import { withRouter } from 'react-router-dom';
import SearchElement from './SearchElement'
import { Card, Row, Spinner } from 'react-bootstrap';

function SearchCard(props) {

	const appTreeId = localStorage.getItem("appTreeId")

	function SearchLine(props) {
		return (
			props.sentences.map((sentence) => (
				((!sentence.isReference && props.showMenus) || (sentence.isReference && props.showSentences)) &&
				((appTreeId !== sentence.appTreeId && !props.showCurrent) || (appTreeId == sentence.appTreeId && props.showCurrent)) &&
				<SearchElement
					sentence={sentence}
					nodeId={sentence.nodeId}
					isSentence={props.showSentences}
				/>
			))
		)
	}

	return (
		<Row className="node-row semi-bold" >
			<Card className='card-standard transparent' >
				<Card.Body>
					<Card.Title className="mb-4 text-center">
						<strong>{props.title}</strong>
					</Card.Title>
					{props.readyToDisplay ?
						<SearchLine
							sentences={props.sentences}
							showMenus={props.showMenus}
							showSentences={props.showSentences}
							appTree={appTreeId}
							showCurrent={props.showCurrent}
						/>
						:
						<div className="text-center mb-4">
							<Spinner animation="border" size='sm' />
						</div>
					}
				</Card.Body>
			</Card>
		</Row>
	)
}

export default withRouter(SearchCard);
