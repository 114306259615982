import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { CURRENT_SPEAKER_HCW } from '../../js/constants/appConstants';
import { IoCloudDownloadOutline } from 'react-icons/io5';
import { _DOMAIN, API_FILES } from '../../js/constants/apiConstants';
// import PDFViewer from '../PDFViewer';
import { IoPrintOutline, IoVolumeHighSharp } from 'react-icons/io5';
import PDFOptionsModal from '../PDFOptionsModal';

// TODO: Move Consejos escritos functionality into its own component
// TODO: Clean up this components (remove var variables, use destructuring to receive props)
function NodeTree(props) {
  const [viewPDF, setViewPDF] = useState(false)
  const [filePDF, setFilePDF] = useState()
  const [filePatientPDF, setFilePatientPDF] = useState()

  var node1SentenceId = props.sentence1.id
  var node1Value = props.sentence1.value ?? '-'
  var node2SentenceId = props.sentence2.id
  var node2Value = props.sentence2.value ?? '-'

  if (props.currentSpeaker !== CURRENT_SPEAKER_HCW) {
    var node1SentenceId = props.sentence2.id
    var node1Value = props.sentence2.value ?? '-'
    var node2SentenceId = props.sentence1.id
    var node2Value = props.sentence1.value ?? '-'
  }
  var switchAlternate = props.showTranslation

  var playSentence = node2SentenceId
  if (!props.showTranslation) playSentence = null

  const getConsejosEscritosPDF = () => {
    if (props.downloadConsejosEscritos) {
      // * The Url is passed to PDFViewer component where react-pdf library handles the downloading with just the url
      const filePharmacistUrl = `${_DOMAIN}${API_FILES}?localeSentenceId=${props.sentence1.id}`
      const filePatientUrl = `${_DOMAIN}${API_FILES}?localeSentenceId=${props.sentence2.id}`

      setFilePDF(filePharmacistUrl)
      setFilePatientPDF(filePatientUrl)
      setViewPDF(!viewPDF)
    }
  }

  return (
    <>
      <div
        className={`py-2 ${props.styling}`}
        onClick={props.downloadConsejosEscritos ? null : () => props.changeNode(props.id, props.isLeaf, switchAlternate, playSentence)}
      >
        <Col
          onClick={props.downloadConsejosEscritos ? () => getConsejosEscritosPDF() : null}
        >
          <Row>
            <Col>
              <div className="text-center">
                {props.styling === "consejos-escritos semi-bold" &&
                  <IoPrintOutline className="inline-icon" style={{ height: "22px" }} />
                }
                <span className='sentence'>{node1Value}</span>
              </div>
            </Col>
            {props.downloadConsejosEscritos ?
              <IoCloudDownloadOutline className='list-icon' />
              : null
            }
          </Row>
          {props.showTranslation && !props.downloadConsejosEscritos &&
            <Row>
              <Col>
                <div className="text-center font-italic">
                  <span className='sentence'>{node2Value}</span>
                </div>
              </Col>
              <IoVolumeHighSharp className='list-icon' />
            </Row>
          }
        </Col>
      </div>
      {viewPDF && <PDFOptionsModal filePDF={filePDF} filePatientPDF={filePatientPDF} viewPDF={viewPDF} setViewPDF={setViewPDF} />}
    </>
  )
}

export default withRouter(NodeTree);
