import React, { useState } from "react"
import { Form } from "react-bootstrap";
import { IoAddOutline } from "react-icons/io5";

function PerformanceCookies({ performanceCookiesAccepted, setPerformanceCookiesAccepted }) {
  const [toggle, setToggle] = useState(false)
  // const [switchState, setSwitchState] = useState(true);

  function toggleItem() {
    setToggle(!toggle)
  }

  function handlePerformanceCookiesAccepted(e) {
    setPerformanceCookiesAccepted(e.target.checked)
  }

  return (
    <div id="cookie-item-container" style={{ borderTop: 0 }}>
      <div id="cookie-item" onClick={toggleItem}>
        <div id="cookie-item-title" >
          <IoAddOutline id="cookie-item-icon" />
          <span id="cookie-item-text">Cookies de rendimiento</span>
        </div>
        <Form.Check
          type="switch"
          id="cookie-item-switch"
          defaultChecked={performanceCookiesAccepted}
          onChange={handlePerformanceCookiesAccepted}
        />
      </div>
      {toggle &&
        <div id="cookie-item-hidden-text">
          <span>
            Estas cookies nos permiten contar las visitas y fuentes de circulación para poder medir y mejorar el desempeño de nuestro sitio.
            Nos ayudan a saber qué páginas son las más o menos populares, y ver cuántas personas visitan el sitio.
            Toda la información que recogen estas cookies es agregada y, por lo tanto, anónima.
            Si no permite estas cookies no sabremos cuándo visitó nuestro sitio, y por lo tanto no podremos saber cuándo lo visitó.
          </span>
        </div>
      }
    </div>
  )
}

export default PerformanceCookies