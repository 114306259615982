import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { IoSearchOutline } from "react-icons/io5";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setSearchString, setNodesHistory } from "../../js/actions/index";

const mapStateToProps = state => {
  return {
    searchString: state.searchString,
    currentNodeId: state.currentNodeId,
    nodesHistory: state.nodesHistory,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setSearchString, setNodesHistory }, dispatch)
}

const DisplaySearchBar = (props) => {
  const { t, i18n } = useTranslation('common');
  const [searchStringTemp, setSearchStringTemp] = useState(null)


  function handleSearchSubmit() {
    if (searchStringTemp.length > 2) {
      props.setSearchString(searchStringTemp)
      props.setNodesHistory([...props.nodesHistory, props.currentNode])
      setSearchStringTemp('')
      props.history.push('/search')
    }
  }

  function handleSearchString(e) {
    setSearchStringTemp(e.target.value)
  }

  return (
    <div className="search-bar">
      <Form onSubmit={e => { e.preventDefault(); handleSearchSubmit(); }}>
        <div class="row no-gutters">
          <div class="col">
            <Form.Control
              value={searchStringTemp}
              type="text"
              placeholder={t('app.search')}
              className="mr-sm-2 search-input"
              onChange={handleSearchString} />
          </div>
          <div class="col-auto">
            <button onClick={handleSearchSubmit} class="btn btn-outline-secondary border-left-0 rounded-0 rounded-right search-button" type="button">
              <IoSearchOutline />
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DisplaySearchBar));