
export const _DOMAIN = "https://inet.universaldoctor.com"

export const ACCESS_TOKEN_NAME = 'ud_token';

export const API_LOGIN = '/api/v2/auth/login';
export const API_LOGOUT = '/api/v2/auth/logout';
export const API_USER = '/api/auth/user';
// export const API_LOCALES = '/api/global/locales';
export const API_LOCALES_APPTREE = '/api/global/apptree-locales';
export const API_TREE = '/api/get-tree';
export const API_NODE = '/api/get-node';
export const API_AUDIO = '/api/get-audio';
export const API_FILES = '/api/get-files';
export const API_SENTENCE = '/api/get-sentence';
export const API_AUTOLOGIN = '/api/v2/auth/autologin';

export const LOADING_STATUS_INIT = 0;
export const LOADING_STATUS_START = 1;
export const LOADING_STATUS_STARTED = 2;
export const LOADING_STATUS_ENDED = 9;
export const LOADING_STATUS_ERROR = -1;


