import {
    SET_LOCALE_HCW,
    SET_LOCALE_PATIENT,
    SET_ALTERNATE,
    SET_CURRENT_SPEAKER,
    SET_SEARCH_STRING,
    SET_CURRENT_NODE_ID,
    SET_ACTOR1_NODE_ID,
    SET_ACTOR2_NODE_ID,
    SET_FORCE_CHANGE_SPEAKER,
    SET_GO_BACK,
    SET_ERROR_MESSAGE,
    SET_APPTREE,
    SET_DOCUMENT,
    SET_DRAWER,
    SET_GO_TO_MAIN_MENU,
    SET_NODES_HISTORY,
    SET_BACK_FROM_SEARCH,
} from "../constants/actionTypes";

export function setLocaleHcw(payload) {
    return { type: SET_LOCALE_HCW, payload };
}

export function setLocalePatient(payload) {
    return { type: SET_LOCALE_PATIENT, payload };
}

export function setAlternate(payload) {
    return { type: SET_ALTERNATE, payload };
}

export function setCurrentSpeaker(payload) {
    return { type: SET_CURRENT_SPEAKER, payload };
}

export function setSearchString(payload) {
    return { type: SET_SEARCH_STRING, payload };
}

export function setCurrentNodeId(payload) {
    return { type: SET_CURRENT_NODE_ID, payload };
}

export function setActor1NodeId(payload) {
    return { type: SET_ACTOR1_NODE_ID, payload };
}

export function setActor2NodeId(payload) {
    return { type: SET_ACTOR2_NODE_ID, payload };
}

export function setForceChangeSpeaker(payload) {
    return { type: SET_FORCE_CHANGE_SPEAKER, payload };
}

export function setGoBack(payload) {
    return { type: SET_GO_BACK, payload };
}

export function setGoToMainMenu(payload) {
    return { type: SET_GO_TO_MAIN_MENU, payload };
}

export function setErrorMessage(payload) {
    return { type: SET_ERROR_MESSAGE, payload };
}

export function setAppTree(payload) {
    return { type: SET_APPTREE, payload };
}

export function setDocument(payload) {
    return { type: SET_DOCUMENT, payload };
}

export function setDrawer(payload) {
    return { type: SET_DRAWER, payload };
}

export function setNodesHistory(payload) {
    return { type: SET_NODES_HISTORY, payload }
}

export function setBackFromSearch(payload) {
    return { type: SET_BACK_FROM_SEARCH, payload }
}