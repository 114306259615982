import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_AUTOLOGIN, ACCESS_TOKEN_NAME, _DOMAIN } from '../../js/constants/apiConstants';
import { withRouter } from "react-router-dom";
import { setAppTree } from "../../js/actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Spinner } from 'react-bootstrap';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setAppTree }, dispatch)
}

function AutoLogin(props) {
    const [status, setStatus] = useState(null)

    const redirectToLogin = () => {
        props.history.push('/');
    }

    const redirectToHome = () => {
        props.history.push('/home');
    }

    useEffect(() => {
        if (status === null) {
            setStatus('a')
            var config = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: false
            }

            var url = _DOMAIN + API_AUTOLOGIN
            const urlParams = new URLSearchParams(window.location.search);
            const autoLoginToken = 'sSnNBd6uLhRKFKiRDix02674XZ8'//urlParams.get('token')
            if (autoLoginToken) url = url + '?token=' + autoLoginToken

            axios.get(url, config)
                .then(function (response) {
                    if (response.status === 200) {
                        localStorage.setItem(ACCESS_TOKEN_NAME, response.data.data.access_token);
                        if (response.data.data.apps.length > 0 && response.data.data.apps[0].trees.length > 0) {
                            props.setAppTree(response.data.data.apps[0].trees[0].id)
                            localStorage.setItem("appTreeId", response.data.data.apps[0].trees[0].id)
                            redirectToHome();
                        } else {
                            console.log('no tree available')
                        }
                    } else {
                        redirectToLogin()
                    }
                })
                .catch(function (error) {
                    redirectToLogin();
                });
        }
    });

    return (
        <div className="text-center mb-4">
            <Spinner animation="border" size='sm' />
        </div>
    )
}

export default withRouter(connect(null, mapDispatchToProps)(AutoLogin));
