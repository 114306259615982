import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ACCESS_TOKEN_NAME, API_USER, _DOMAIN } from '../../js/constants/apiConstants';
import axios from 'axios'
import NewLocaleSelector from '../LocaleSelector/NewLocaleSelector';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import CookieConsent from 'react-cookie-consent';
import CookiesConsent from '../CookiesConsent/CookiesConsent';

function Home(props) {
  const consent = localStorage.getItem('cookieConsent');

  useEffect(() => {
    axios.get(_DOMAIN + API_USER, { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } })
      .then(function (response) {
        if (response.status !== 200) {
          redirectToLogin()
        }
      })
      .catch(function (error) {
        redirectToLogin()
      });
  })

  useEffect(() => {
    document.body.classList.add("no-scroll"); // disable scrolling when modal is open

    return () => {
      document.body.classList.remove("no-scroll"); // re-enable scrolling when modal is closed
    };
  }, []);

  function redirectToLogin() {
    props.history.push('/');
  }

  return (
    <>
      <div style={{ position: "relative", height: "100%" }}>
        <div className="mt-4 mb-4 pt-2" style={{ display: "flex", justifyContent: "space-between" }}>
          <Row style={{ justifyContent: "space-between" }}>
            <Col md={7}>
              <div className='title-container'>
                <h1>Comencemos</h1>
                <div className="body-text">
                  <p>UniversalPharmacist es una aplicación que te permitirá atender a clientes y usuarios de múltiples países que no dominan nuestros idiomas suficientemente para seguir una consulta farmacéutica.</p>
                  <p>Con la Aplicación tendrás la posibilidad de preguntar en formato audio centenares de preguntas y dar instrucciones claras (Ej: Explicar la posología en uno de los 14 idiomas de la Aplicación).</p>
                  <p>A la vez el farmacéutico/a podrá imprimir y/o enviar a los usuarios diferentes consejos escrito.</p>
                </div>
              </div>
            </Col>
            <Col md={5} style={{ marginTop: "16px" }}>
              <NewLocaleSelector />
            </Col>
          </Row>
        </div>
        <div style={{ position: "absolute", right: 0, bottom: 34, fontSize: "8px" }}>
          <span>ESDIG23001 Feb'23</span>
        </div>
      </div>
      <Footer />
      {!consent && <CookiesConsent />}
    </>
  )

}

export default withRouter(Home);
