import React, { useEffect, useState } from 'react';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Main from './components/Main/Main';
import Search from './components/Search/Search';
import PrivateRoute from './utils/PrivateRoute';
import ToastComponent from './components/ToastComponent/ToastComponent';
import AutoLogin from './components/Autologin/AutoLogin';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.scss';

function App() {
  const [title, updateTitle] = useState();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'false') {
      const ga4Script = document.getElementsByTagName('script')[0];

      ga4Script.remove();
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Header title={title} />
        <div className="container d-flex align-items-center flex-column">
          <Switch>
            <Route path="/" exact={true}>
              <AutoLogin />
            </Route>
            <Route path="/acces/:token">
              <AutoLogin />
            </Route>
            <PrivateRoute path="/home">
              <Home />
            </PrivateRoute>
            <PrivateRoute path="/main">
              <Main />
            </PrivateRoute>
            <PrivateRoute path="/search">
              <Search />
            </PrivateRoute>
          </Switch>
          <ToastComponent />
        </div>
      </div>
    </Router>
  );
}

export default App;
