export const SET_LOCALE_HCW = "SET_LOCALE_HCW";
export const SET_LOCALE_PATIENT = "SET_LOCALE_PATIENT";
export const SET_ALTERNATE = "SET_ALTERNATE";
export const SET_CURRENT_SPEAKER = "SET_CURRENT_SPEAKER";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const SET_CURRENT_NODE_ID = "SET_CURRENT_NODE_ID";
export const SET_ACTOR1_NODE_ID = "SET_ACTOR1_NODE_ID";
export const SET_ACTOR2_NODE_ID = "SET_ACTOR2_NODE_ID";
export const SET_FORCE_CHANGE_SPEAKER = "SET_FORCE_CHANGE_SPEAKER";
export const SET_GO_BACK = "SET_GO_BACK";
export const SET_GO_TO_MAIN_MENU = "SET_GO_TO_MAIN_MENU";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_APPTREE = "SET_APPTREE";
export const SET_DOCUMENT = "SET_DOCUMENT";
export const SET_DRAWER = "SET_DRAWER";
export const SET_NODES_HISTORY = "SET_NODES_HISTORY";
export const SET_BACK_FROM_SEARCH = "SET_BACK_FROM_SEARCH"