import { Button, Modal } from "react-bootstrap"
import React, { useState } from 'react';
import cookiesPolicy from '../../assets/files/Menarini_Cookies_Policy_Esp-V2.pdf';
import MoreOptionsModal from "./MoreOptions/MoreOptionsModal";

export default function CookiesConsent() {
  const [show, setShow] = useState(true)
  const [showMoreOptionsModal, setShowMoreOptionsModal] = useState(false);

  // ! TODO NEXT: Set local storage item for cookies consent status. Add functionality in accept and reject here, and in App.js an useEffect to check for it

  function accept() {
    localStorage.setItem("cookieConsent", "true")
    setShow(false)
    setShowMoreOptionsModal(false)
  }

  function reject() {
    localStorage.setItem("cookieConsent", "false")
    setShow(false)
    setShowMoreOptionsModal(false)
    const ga4Script = document.getElementsByTagName('script')[0];

    ga4Script.remove();
  }

  function showMoreOptions() {
    setShowMoreOptionsModal(!showMoreOptionsModal);
  }

  return (
    <>
      <Modal
        show={show}
        dialogClassName='cookie-consent-modal'
        size='md'
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div className="cookie-consent-modal-content">
          <p style={{ fontSize: "10px" }}>
            Nuestro sitio web utiliza cookies, incluidas cookies de terceros. Además de las cookies estrictamente necesarias que son esenciales para navegar por el sitio web, el sitio puede presentar cookies de rendimiento, que realizan estadísticas agregadas sobre el uso del sitio web.
            Si desea recibir más información haga clic en la Política de Cookies.
            Si desea consultar más información sobre las cookies efectivamente empleadas en nuestro sitio web y/o configurarlas o rechazarlas, haga clic en MÁS OPCIONES.
            Al hacer clic en RECHAZARLAS TODAS, sólo se instalarán en su dispositivo las cookies estrictamente necesarias.
            Al hacer clic en ACEPTO estará aceptando todas las cookies.{" "}
            <a href={cookiesPolicy} target="_blank" className="cookie-consent-links">Política de Cookies</a>
          </p>
          <div style={{ textAlign: "right" }}>
            <a
              href="#"
              onClick={showMoreOptions}
              className="cookie-consent-links"
              style={{ fontSize: "10px", position: "absolute", left: "20px", bottom: "26px" }}
            >
              MÁS OPCIONES
            </a>
            <Button
              className="cookie-consent-buttons"
              onClick={reject}
            >
              RECHAZARLAS TODAS
            </Button>
            <Button
              className="cookie-consent-buttons"
              style={{ marginLeft: "4px" }}
              onClick={accept}
            >
              ACEPTO
            </Button>
          </div>
        </div>
      </Modal>
      <MoreOptionsModal show={showMoreOptionsModal} goBack={showMoreOptions} accept={accept} reject={reject} />
    </>
  )
}
