import {
    CURRENT_SPEAKER_HCW
} from '../constants/appConstants';

import {
    SET_LOCALE_HCW,
    SET_LOCALE_PATIENT,
    SET_ALTERNATE,
    SET_CURRENT_SPEAKER,
    SET_SEARCH_STRING,
    SET_CURRENT_NODE_ID,
    SET_ACTOR1_NODE_ID,
    SET_ACTOR2_NODE_ID,
    SET_FORCE_CHANGE_SPEAKER,
    SET_GO_BACK,
    SET_ERROR_MESSAGE,
    SET_APPTREE,
    SET_DOCUMENT,
    SET_DRAWER,
    SET_GO_TO_MAIN_MENU,
    SET_NODES_HISTORY,
    SET_BACK_FROM_SEARCH,
} from "../constants/actionTypes";

const initialState = {
    localeHcw: null,
    localePatient: null,
    alternate: 1,
    currentSpeaker: CURRENT_SPEAKER_HCW,
    searchString: null,
    currentNodeId: null,
    actor1NodeId: null,
    actor2NodeId: null,
    forceChangeSpeaker: false,
    goBack: false,
    goToMainMenu: false,
    errorMessage: null,
    appTree: null,
    document: false,
    drawer: false,
    nodesHistory: [],
    backFromSearch: [],
};

function rootReducer(state = initialState, action) {
    if (action.type === SET_LOCALE_HCW) {
        return Object.assign({}, state, {
            localeHcw: action.payload
        });
    }
    if (action.type === SET_LOCALE_PATIENT) {
        return Object.assign({}, state, {
            localePatient: action.payload
        });
    }
    if (action.type === SET_ALTERNATE) {
        return Object.assign({}, state, {
            alternate: action.payload
        });
    }
    if (action.type === SET_CURRENT_SPEAKER) {
        return Object.assign({}, state, {
            currentSpeaker: action.payload
        });
    }
    if (action.type === SET_SEARCH_STRING) {
        return Object.assign({}, state, {
            searchString: action.payload
        });
    }
    if (action.type === SET_CURRENT_NODE_ID) {
        return Object.assign({}, state, {
            currentNodeId: action.payload
        });
    }
    if (action.type === SET_ACTOR1_NODE_ID) {
        return Object.assign({}, state, {
            actor1NodeId: action.payload
        });
    }
    if (action.type === SET_ACTOR2_NODE_ID) {
        return Object.assign({}, state, {
            actor2NodeId: action.payload
        });
    }
    if (action.type === SET_FORCE_CHANGE_SPEAKER) {
        return Object.assign({}, state, {
            forceChangeSpeaker: action.payload
        });
    }
    if (action.type === SET_GO_BACK) {
        return Object.assign({}, state, {
            goBack: action.payload
        });
    }
    if (action.type === SET_GO_TO_MAIN_MENU) {
        return Object.assign({}, state, {
            goToMainMenu: action.payload
        });
    }
    if (action.type === SET_ERROR_MESSAGE) {
        return Object.assign({}, state, {
            errorMessage: action.payload
        });
    }
    if (action.type === SET_APPTREE) {
        return Object.assign({}, state, {
            appTree: action.payload
        });
    }
    if (action.type === SET_DOCUMENT) {
        return Object.assign({}, state, {
            document: action.payload
        });
    }
    if (action.type === SET_DRAWER) {
        return Object.assign({}, state, {
            drawer: action.payload
        });
    }
    if (action.type === SET_NODES_HISTORY) {
        return { ...state, nodesHistory: action.payload }
    }
    if (action.type === SET_BACK_FROM_SEARCH) {
        return { ...state, backFromSearch: action.payload }
    }

    return state;
}

export default rootReducer;