import React, { useState } from "react"
import axios from 'axios'
import { ListGroup, Modal } from "react-bootstrap"
import { ACCESS_TOKEN_NAME } from "../js/constants/apiConstants"
import QRGenerator from "./Home/QRGenerator"

const PDFOptionsModal = ({ filePDF, filePatientPDF, viewPDF, setViewPDF }) => {
  const [viewQR, setViewQR] = useState(false)

  const downloadPDF = () => {
    let alink = document.createElement('a');
    alink.href = filePDF;
    alink.download = 'SamplePDF.pdf';
    alink.click();
  }

  const printPDF = (patient = false) => {
    // TODO: Will need to parse the file when downloading it
    const config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) }, responseType: "blob" }
    axios(patient ? filePatientPDF : filePDF, config)
      .then(response => {//Create a Blob from the PDF Stream
        const preparePDFDownload = new Blob(
          [response.data],
          { type: "application/pdf" });//Build a URL from the file
        const fileURL = window.URL.createObjectURL(preparePDFDownload);//Open the URL on new Window
        // Setting various property values
        window.open(fileURL);
      })
  }

  const handleClose = () => {
    setViewPDF(!viewPDF)
  }

  const handleViewQR = () => {
    setViewQR(!viewQR)
  }

  return (
    <>
      <Modal
        size='xs'
        show={viewPDF}
        onHide={handleClose}
        backdrop='static'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        dialogClassName='pdf-modal'
      >
        <Modal.Header
          className='pdf-modal-header'
          closeButton
        >
          <div
            className="pdf-modal-header-container"
          >
            <Modal.Title
              className='pdf-modal-title'
            >
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body
          className='pdf-options-body'
        >
          <ListGroup className='card-standard transparent' style={{ marginBottom: 0 }} >
            <ListGroup.Item className="node-tree semi-bold" style={{ textAlign: 'center' }} action onClick={() => downloadPDF()}>
              <span className='sentence'>Descargar PDF en el lenguaje del farmacéutico</span>
            </ListGroup.Item>
            <ListGroup.Item className="node-tree semi-bold" action onClick={handleViewQR}>
              <div className="text-center">
                <span className='sentence'>Descargar PDF en el lenguaje del paciente</span>
              </div>
              <div className="text-center font-italic">
                <span className='sentence'>
                  Código QR para guardarlo en el dispositivo del paciente
                </span>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="node-tree semi-bold" style={{ textAlign: 'center' }} action onClick={() => printPDF()}>
              <span className='sentence'>Imprimir en el lenguaje del farmacéutico</span>
            </ListGroup.Item>
            <ListGroup.Item className="node-tree semi-bold" style={{ textAlign: 'center', marginBottom: 0 }} action onClick={() => printPDF(true)}>
              <span className='sentence'>Imprimir en el lenguaje del paciente</span>
            </ListGroup.Item>
          </ListGroup>
        </Modal.Body>
        <Modal.Footer className='pdf-footer-container'>
        </Modal.Footer>

      </Modal>
      <QRGenerator show={viewQR} url={filePatientPDF} close={handleViewQR} />
    </>

  )
}

export default PDFOptionsModal