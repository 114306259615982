import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { API_SENTENCE, ACCESS_TOKEN_NAME, _DOMAIN, } from '../../js/constants/apiConstants';
import { LOADING_STATUS_START, LOADING_STATUS_STARTED, LOADING_STATUS_ENDED, LOADING_STATUS_ERROR } from '../../js/constants/apiConstants';
import axios from 'axios'
import { bindActionCreators } from 'redux'
import { useTranslation } from "react-i18next";
import { setCurrentNodeId, setSearchString, setErrorMessage, setGoBack, setNodesHistory, setBackFromSearch } from "../../js/actions/index";
import SearchCard from './SearchCard'
import DisplaySearchBar from './DisplaySearchBar'

const mapStateToProps = state => {
	return {
		localeHcw: state.localeHcw,
		localePatient: state.localePatient,
		searchString: state.searchString,
		currentNodeId: state.currentNodeId,
		appTree: state.appTree,
		goBack: state.goBack,
		nodesHistory: state.nodesHistory,
	};
};

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setCurrentNodeId, setSearchString, setErrorMessage, setGoBack, setNodesHistory, setBackFromSearch }, dispatch)
}

function Search(props) {
	const { t } = useTranslation('common');
	const [loadingStatus, setLoadingStatus] = useState(LOADING_STATUS_START)
	const [sentences, setSentences] = useState([])
	const [searchStringSearched, setSearchStringSearched] = useState(null)

	const localeHcw = localStorage.getItem("localeHcw")
	const localePatient = localStorage.getItem("localePatient")

	useEffect(() => {
		if (props.searchString !== null) {
			setLoadingStatus(LOADING_STATUS_STARTED)
			setSearchStringSearched(props.searchString)
			const url = _DOMAIN + API_SENTENCE
				+ '?sentence=' + props.searchString
				+ '&locale-code-1=' + localeHcw //props.localeHcw
				+ '&locale-code-2=' + localePatient //props.localePatient
				+ '&tree-id=' + '17'
			const config = { headers: { Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN_NAME) } }
			axios.get(url, config)
				.then(function (response) {
					// props.setSearchString(null)
					// props.setNodesHistory([...props.nodesHistory, [{ isSearchString: true, searchString: props.searchString }]])
					if (response.status === 200) {
						setSentences(response.data.data.values)
						setLoadingStatus(LOADING_STATUS_ENDED)
					} else {
						setLoadingStatus(LOADING_STATUS_ERROR)
					}
				})
				.catch(function (error) {
					props.setSearchString(null)
					setLoadingStatus(LOADING_STATUS_ERROR)
					props.setErrorMessage(t('api.generic_error'))
				});
		}
	}, [props, localeHcw, localePatient, t])

	useEffect(() => {
		function goBack() {
			const lastNode = [...props.nodesHistory].pop()
			const removedLastNodeFromHistory = props.nodesHistory.slice(0, -1)
			props.setNodesHistory(removedLastNodeFromHistory)
			props.setSearchString(null)
			// * To call the display node when going back from search
			props.setBackFromSearch(lastNode)
			props.history.push('/main')
		}
		if (props.goBack) {
			goBack()
			props.setGoBack(false)
		}
	}, [props])

	if (!props.localeHcw || !props.localePatient) {
		props.history.push('/home')
		return null
	}

	return (
		<div className="mt-2 search-page-body" >
			<DisplaySearchBar />
			<SearchCard
				title={t('search.nodes_for') + " '" + searchStringSearched + "'"}
				showMenus={true}
				showSentences={false}
				sentences={sentences}
				readyToDisplay={loadingStatus === LOADING_STATUS_ENDED}
				appTree={props.appTree}
				showCurrent={true}
			/>

			<SearchCard
				title={t('search.sentences_for') + " '" + searchStringSearched + "'"}
				showMenus={false}
				showSentences={true}
				sentences={sentences}
				readyToDisplay={loadingStatus === LOADING_STATUS_ENDED}
				appTree={props.appTree}
				showCurrent={true}
			/>
		</div>
	)

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
