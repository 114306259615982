import React, { useState } from "react"
import { IoAddOutline } from "react-icons/io5";

function MandatoryCookies() {
  const [toggle, setToggle] = useState(false)

  function toggleItem() {
    setToggle(!toggle)
  }

  return (
    <div id="cookie-item-container">
      <div id="cookie-item" onClick={toggleItem}>
        <div id="cookie-item-title" >
          <IoAddOutline id="cookie-item-icon" />
          <span id="cookie-item-text">Cookies estrictamente necesarias</span>
        </div>
        <span id="cookie-item-value">Activas siempre</span>
      </div>
      {toggle &&
        <div id="cookie-item-hidden-text">
          <span>
            Estas cookies son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas.
            Usualmente están configuradas para responder a acciones hechas por usted para recibir servicios, tales como ajustar sus preferencias de privacidad, iniciar sesión en el sitio, o llenar formularios.
            Usted puede configurar su navegador para bloquear o alertar la presencia de estas cookies, pero algunas partes del sitio web no funcionarán.
            Estas cookies no guardan ninguna información personal identificable.
          </span>
        </div>
      }
    </div>
  )
}

export default MandatoryCookies